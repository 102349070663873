import { Image } from "react-bootstrap";
import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { isEmpty } from "lodash";

const Filter = (({ options, parentState, parentSetState, filters, style, itemClassName, itemStyle, buttonStyle }, ref) => {
    const [selected, setSelected] = useState(filters || []);
    const selectFilter = () => {
        parentSetState({
            filters: selected,
            showFilter: ''
        });
    };

    useImperativeHandle(ref, () => ({
        cleanSelected() {
            setSelected([]);
        }
    }));

    const currentState = (term, select_one) => {
        if (select_one) {
            setSelected([term]);
            return;
        }
        const findTerm = selected.find(f => f.id === term.id);
        if (!findTerm) {
            setSelected(selected.concat([term]));
        } else {
            setSelected(selected.filter(f => f.id !== term.id));
        }
    };
    const genRootFilter = (options || []).map((item, index) => {
        return (
            <div
                key={index}
                className={`filterItem dropdown mr-2 ${itemClassName}`}
                style={{ display: 'inline-block', ...itemStyle }}
            >
                <button
                    className="btn btn--dropdown text-left w-100"
                    data-toggle="dropdown"
                    style={{ ...buttonStyle }}
                    onClick={() => {
                        parentSetState({ showFilter: item.name });
                        setSelected(filters);
                    }}
                >
                    {item.name}
                </button>
                <div
                    className={`pc-only dropdown-menu dropdown-menu--mw290 ${
                        parentState.showFilter === item.name ? 'show' : ''
                    }`}
                >
                    <div className="filterBox">
                        {/*<div className="filterHeader">*/}
                        {/*    <span className="filter-tlt">Sắp xếp</span>*/}
                        {/*    <span className="filter-close">*/}
                        {/*        <Image layout="fill" src="/images/icon-close.png" alt=""/>*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                        <ul className="filter-list">
                            {(item?.terms || []).map((term, index) => {
                                const findTerm =
                                    !isEmpty(selected) &&
                                    selected.find((f) => f.id === term.id);
                                const activeClass = findTerm ? 'active' : '';
                                return (
                                    <li
                                        key={index}
                                        className={activeClass}
                                        onClick={() =>
                                            currentState(term, item.select_one)
                                        }
                                    >
                                        {term.text}
                                    </li>
                                );
                            })}
                        </ul>
                        <div className="filter-btn">
                            <button
                                className="btn btn--blue"
                                onClick={() => {
                                    selectFilter();
                                }}
                            >
                                Áp dụng
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    const selectedOption = (options || []).find(x => parentState.showFilter === x.name);
    return (
        <>
            <div className={`filter ${parentState.showFilter ? "filter-m-p" : ''}`} style={{
                // 'zIndex': '2',
                whiteSpace: 'nowrap',
                overflowX: 'scroll',
                ...style
            }}>
                {genRootFilter}
            </div>
            {parentState.showFilter && 
                <div className="filterItem">
                    <div className={`sp-only dropdown-menu dropdown-menu--mw290 ${parentState.showFilter ? 'show' : ''}`} >
                        <div className="filterBox">
                            <div className="filterHeader">
                                <span className="filter-tlt">{parentState.showFilter}</span>
                                <span className="filter-close" onClick={() => parentSetState({ showFilter: false })}>
                                    <Image layout="fill" src="/images/icon-close.png" alt="" />
                                </span>
                            </div>
                            <ul className="filter-list">
                                {(selectedOption?.terms || []).map((term, index) => {
                                    const findTerm = !isEmpty(selected) && selected.find(f => f.id === term.id);
                                    const activeClass = findTerm ? 'active' : '';
                                    return (<li key={index} className={activeClass} onClick={() => currentState(term, selectedOption.select_one)}>{term.text}</li>);
                                })}
                            </ul>
                            <div className="filter-btn">
                                <button className="btn btn--blue" onClick={() => {
                                    selectFilter();
                                }}>Áp dụng</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
});

export default forwardRef(Filter);
