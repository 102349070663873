import { Image } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import StarList from 'components/shop/review/starList';
import { getThumbnail } from 'components/cong-dong/articleComponent';

export default function Review({ items }) {
    if (isEmpty(items)) return <></>;
    const genReview = items.map((item, index) => {
        const link = `/review/${item.id}/${item.slug}`;
        const thumbnal = getThumbnail(item);
        return (
            <li key={index} className="cursor-pointer">
                <div className="review-thumb">
                    <a href={link}>
                        <Image style={{ cursor: "pointer" }} alt="" layout="fill" src={thumbnal} align="" />
                    </a>
                </div>
                <div className="review-info">
                    {item.rating > 0 && <StarList number={item.rating} />}
                    {!isEmpty(item.tags?.data) && <div className="tag_list">
                        {item.tags.data.slice(0, 3).map((tag, index) => <span key={index} className="tag-items">{tag.name}</span>)}
                    </div>}
                    <a className="desc" href={link}>
                        {item.title}
                    </a>
                    <a href={link}>
                        <span style={{ cursor: "pointer" }} className="review-athor">{item.user?.display_name}</span>
                    </a>
                </div>
            </li>
        );
    });

    return (
        <div className="bb-sp mb-5">
            <div className="groupCategory-title">
                <h2 className="groupCategory-tlt">Review</h2>
                <a href={`/review`} className="readMore">Xem tất cả</a>
            </div>
            <ul className="review_list">
                {genReview}
            </ul>
        </div>
    );
}