/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { SHIT_IMG_URL } from 'utilities/constants';
import {isEmpty} from 'lodash';
import { getUrlName } from 'utilities/helper';

export default function Index({item}) {
    let imgUrl = '';
    let imgUrlMobile = '';
    if (item.banner.startsWith('/media')) imgUrl = SHIT_IMG_URL + item.banner;
    if (item.banner.startsWith('http')) imgUrl = item.banner;
    // if (item.banner.startsWith('[{')) imgUrl = JSON.parse(item.banner)[0][0].url;
    if (item.banner.startsWith('[{')) {
        const jsonParse = JSON.parse(item.banner);
        if( !isEmpty(jsonParse)) {
            if (!isEmpty(jsonParse[0])) {
                imgUrl = jsonParse[0][0].url;
            }
        }
    }

    if (item.banner_mobile.startsWith('/media')) imgUrlMobile = SHIT_IMG_URL + item.banner_mobile;
    if (item.banner_mobile.startsWith('http')) imgUrlMobile = item.banner_mobile;
    if (item.banner_mobile.startsWith('[{')) {
        const jsonParse = JSON.parse(item.banner_mobile);
        if( !isEmpty(jsonParse)) {
            if (!isEmpty(jsonParse[0])) {
                imgUrlMobile = jsonParse[0][0].url;
            }
        }
    }

    return (
        <div className="dealHot-info p-1">
            <Link href={item.is_landing_page ? `/shop/deal-hot/landing/${item.id}/${getUrlName(item.title)}` : `/shop/deal-hot/${item.id}/${getUrlName(item.title)}`}>
                <a>
                    <div className="dealHot-thumb">
                        <img src={imgUrlMobile || imgUrl} alt="" />
                    </div>
                    <p className="dealHot-title">{item.title}</p>
                </a>
            </Link>
        </div>
    );
};