/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import Slider from "react-slick";
import Product from "components/shop/product";
import { isEmpty } from 'lodash';
import http from 'utilities/http';
import { useCart } from "framework/store";

export default function Index({ }) {
    const [stateStore] = useCart();

    const [activeCategory, setActiveCategory] = useState(0);
    const [hotProducts, setHotProducts] = useState([]);
    const [randomCategories, setRandomCategories] = useState([]);

    useEffect(() => {
        if (isEmpty(stateStore.shopCategories)) return;
        setRandomCategories(stateStore.shopCategories
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value));
    }, [stateStore.shopCategories]);

    const settingsHotProduct = {
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    const genCategoriesHot = randomCategories.map((item, index) => {
        const activeClass = activeCategory == index ? 'active pt-0' : 'pt-0';
        return <li key={index}>
            <span className={activeClass} onClick={() => {
                clickCategory(index, item.id);
            }
            }>
                {item.name}
            </span>
        </li>;
    });

    const clickCategory = async (index, categoryId) => {
        try {
            const result = await http('/rest/V1/vne/hot-product', {
                filters: [[{
                    field: 'category_id',
                    value: categoryId || randomCategories[0].id,
                }]],
                size: 6,
            });
            setHotProducts(result.items);
            setActiveCategory(index);
        } catch (e) {
            console.log(e);
        }
    };

    const genHotProducts = () => {
        hotProducts.length = 6;
        return hotProducts.map((item, index) => {
            if (!item) return <div key={index} className="products"></div>;
            return <Product key={`${item.sku}` + index} item={item} index={index + 1} />;
        });
    };

    useEffect(() => {
        clickCategory(0);
    }, [randomCategories]);

    return <div className="tabsHot mt-0">
        <ul className="nav">
            {genCategoriesHot}
        </ul>
        {/*Tab panes*/}
        <div className="prohot">
            {!isEmpty(hotProducts)
                && <Slider {...settingsHotProduct}>
                    {genHotProducts()}
                </Slider>
            }
        </div>
    </div>;
}