
/* eslint-disable @next/next/no-img-element */
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import http from "utilities/http";
import { API_IMG, LOCAL_API } from "utilities/constants";
import SliderAd from "components/chuyen-gia/slider";
import Link from "next/link";
import DealHot from "components/shop/dealHot";
import ImageStore from "components/cong-dong/imageStore";
import Markets from "components/cong-dong/markets";
import Review from "components/cong-dong/review";
import Product from "components/shop/product";
import { useEffect, useState } from "react";
import { isEmpty, has } from "lodash";
import { getCookie } from "../framework/libs/cookie";
import { TOKEN_CONGDONG } from "../framework/store/constants";
import HomeStory from "components/cong-dong/home-story";
import NewProfessionalComponent from 'components/chuyen-gia/newProfessionalComponent';
import Banner from "components/banner";
import Head from "next/head";
import { getTypeArticle } from 'utilities/helper';
import ListSpHot from 'components/listSpHot';
import { numberWithCommas } from "utilities/helper";
import { useRef } from 'react';
import { replaceAll } from 'utilities/helper';
import parse, { domToReact } from 'html-react-parser';
import dayjs from "dayjs";
import FormDangKyTuVan from "components/don-tu-van/formDangKyTuVan";
import { useCart } from "framework/store";
import ButtonModalDonTuVan from "components/don-tu-van/buttonModalDonTuVan";
import Filter from "components/cong-dong/story/filter";
import { useRouter } from "next/router";
import { Modal, Space, Row, Col } from "antd";
import { MyInput } from "components/don-tu-van/formDangKyTuVan";
import { inputModel, selectModel, validateEmail, validatePhone } from "models/inputModel";
import { toast } from "react-toastify";
import { setCookie } from "framework/libs/cookie";
import { BtnDangBai } from "components/home/header";

const getDataByType = (categories, name, id) => {
    return categories.find(x => x.name.toLowerCase() === name.toLowerCase() || x.id === id) || {};
};

const getSlugByName = (name) => {
    switch (name.toLowerCase()) {
    case 'chuyện nhà':
        return 'chuyen-nha';
    case 'kho 360':
        return 'kho-360';
    case 'kho ảnh':
        return 'album';
    case 'thảo luận':
        return 'thao-luan';
    case 'chợ đồ cũ':
        return 'cho-do-cu';
    case 'review':
        return 'review';
    case 'sự kiện':
        return 'su-kien';
    case 'happynesttv':
        return 'happynesttv';
    case 'ăn - chơi':
        return 'an-choi';
    case 'kho kiến thức':
        return 'kho-kien-thuc';
    case 'sự kiện':
        return 'su-kien';
    case 'xu hướng':
        return 'xu-huong';
    default:
        return 'chuyen-nha';
    }
};

export const renderByType = (category, name) => {
    if (isEmpty(category)) return;

    const data = category.items?.data || [];
    const type = name || category.name;
    const slug = getSlugByName(type);

    if (isEmpty(data)) return <></>;

    switch (type) {
    case 'Chuyện nhà':
        return <HomeStory title={type} items={data} />;
    case 'Kho ảnh':
        return <ImageStore title={type} item={data} slug={slug} />;
    case 'Thảo luận':
        return <Markets title={type} items={data} slug={slug} />;
    case 'Chợ đồ cũ':
        return <Markets title={type} items={data} slug={slug} />;
    case 'Review':
        return <Review items={data} />;
    case 'Sự kiện':
        return <HomeStory title={type} items={data} slug={slug} />;
    case 'Kho 360':
        return <HomeStory title={type} items={data} slug={slug} />;
    default:
        return <HomeStory title={type} items={data} slug={slug} />;
    }
};


export default function Home({ home, dealHots, products, spotlights, flashSales }) {
    const router = useRouter();
    const [state, _setState] = useState({
        top_articles: home.top_articles?.data || [],
        categories: home.categories || [],
        professionals: home.professionals?.data || [],
        members: home.members?.data || [],
        filters: [],
    });
    const setState = (_state = {}) => {
        _setState({
            ...state,
            ..._state
        });
    };
    const [bannerModal, setBannerModal] = useState(false);
    const [stateStore, actions] = useCart();
    const categories = stateStore.shopCategories;

    const fetchHomeData = async () => {
        if (isEmpty(getCookie(TOKEN_CONGDONG))) return;
        const res = await http(`community/home-v2`, {}, { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) }, 'v2');
        if (res?.code == 200) {
            setState({
                top_articles: res.top_articles?.data || [],
                categories: res.categories || [],
                professionals: res.professionals?.data || [],
                members: res.members?.data || [],
            });
        }
    };
    useEffect(() => fetchHomeData(), []);

    //Banner top
    const sliderBannerSetting = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
    };

    //List tabs
    const listTab = [
        {
            name: 'Tìm chuyên gia',
            image: '/images/shortcut/tim-chuyen-gia.png',
            link: '/chuyen-gia',
            className: 'marginLeft',
            textIcon : "New version"
        },
        {
            name: 'Happynest Map',
            image: '/images/shortcut/Map.svg',
            link: '/happynest-map',
            className: 'marginLeft',
            textIcon : ""
        },
        {
            name: 'Flash Sale',
            image: '/images/shortcut/flash-sale.png',
            link: '/shop/flash-sale',
            className: 'marginLeft',
            textIcon : ""
        },
        {
            name: 'Mua sắm',
            image: '/images/shortcut/mua-sam.png',
            link: '/shop',
            textIcon : ""
        },
        {
            name: 'Chuyện nhà',
            image: '/images/shortcut/chuyen-nha.png',
            link: '/chuyen-nha',
            textIcon : ""
        },
        {
            name: 'Kho ảnh',
            image: '/images/shortcut/kho-anh.png',
            link: '/album',
            textIcon : ""
        },
        {
            name: 'Kho kiến thức',
            image: '/images/shortcut/kho-kien-thuc.png',
            link: '/kho-kien-thuc',
            textIcon : ""
        },
        {
            name: 'Thảo luận',
            image: '/images/shortcut/thao-luan.png',
            link: '/thao-luan',
            textIcon : ""
            // className: 'marginLeft',
        },
        {
            name: 'Kho 360',
            image: '/images/shortcut/kho-360-v3.jpg',
            link: '/kho-360',
            textIcon : ""
        },
    ];
    const settingsDealHot = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '40px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    touchMove: true,
                }
            },
        ]
    };
    const settingsFlashSale = {
        infinite: false,
        arrows: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };

    const genDealHots = dealHots.map((item, index) => {
        return <DealHot key={index} item={item} />;
    });

    const sortByPosition = (a, b) => a.position > b.position ? 1 : -1;
    const genCategories = categories.sort(sortByPosition).map((item, index) => {
        const attributeImg = {};
        if (item.custom_attributes) {
            item.custom_attributes.forEach(attr => {
                attributeImg[attr.attribute_code] = attr.value;
            });
        } else {
            attributeImg.image = item.image;
        }
        return <li key={index} style={{ display: 'inline-block' }}>
            <a href={'/shop/' + (attributeImg.url_key || item.url_key)} style={{ whiteSpace: 'break-spaces' }}>
                <div className="serviceList_thumb">
                    <Image src={attributeImg.image || "/images/img-sale01.png"} alt={item.name} width={68} height={70} /></div>
                {item.name}
            </a>
        </li>;
    });

    const livestream = spotlights.find(x => x.is_livestream === true && x.livestream_embedded);
    const createMarkup1 = () => {
        if (livestream.livestream_embedded) {
            let des = livestream.livestream_embedded;
            const options = {
                replace: ({ attribs, children }) => {
                    if (!attribs) {
                        return;
                    }
                    return <iframe src={attribs.src} allowFullScreen={true} allow={attribs.allow}></iframe>;
                }
            };
            return parse(des, options);
        }
    };

    const [minuteNow, setminuteNow] = useState(null);
    useEffect(() => {
        setminuteNow(Math.round(Math.random() * 10));
    }, []);

    const filterRef = useRef();
    const [optionsFilter, setOptionsFilter] = useState([]);
    useEffect(() => {
        if (isEmpty(stateStore.communityCategories)) return;
        const fetchFilter = async () => {
            const res = await http(`community/article/filter/561?per_page=20`, {}, {}, 'v2');
            if (res?.code == 200) {
                const options = getFilters(res?.data || [], stateStore.communityCategories);
                setOptionsFilter(options);
            }
        };
        fetchFilter();
    }, [stateStore.communityCategories]);

    useEffect(() => {
        if (isEmpty(state.filters)) return;
        if (state.filters[0].isChuDe) {
            if (state.filters[0].id === 'happynest-story') {
                window.location.href = '/happynest-story';
                return;
            };
            router.push(state.filters[0].id);
            return;
        };

        Modal.info({
            title: 'Thông báo',
            content: (
                <div>
                    <p>Vui lòng chọn chủ đề trước</p>
                </div>
            ),
            onOk() { },
        });
    }, [state.filters]);

    return (
        <>
            <Head>
                <link rel="shortcut icon" href="/favicon.ico" />
                <meta property="og:image" content="https://happynest.vn/v2019/img/logo-seo-happynest.png"></meta>
            </Head>
            <section className="main main--home pt-0">
                <Banner banner={bannerModal} setBanner={setBannerModal} imgLink="" />
                <div className="wrap">
                    {!isEmpty(state.showFilter) && <div className="backdrop backdrop-filter" onClick={() => {
                        setState({ showFilter: '' });
                        has(filterRef, ['current', 'cleanSelected']) && filterRef.current.cleanSelected();
                    }}></div>}
                    <div className="mt-2 position-relative">
                        <Filter ref={filterRef} options={optionsFilter} parentState={state} filters={state.filters}
                            parentSetState={setState} style={{ paddingRight: '30px' }} />
                        <BtnDangBai/>
                    </div>
                    <SliderAd positionDesktop="1" pageDesktop="1" positionMobile="5" pageMobile="1" />
                    <SliderAd positionMobile="23" pageMobile="1" isPopup={true} />
                    <div className="banner-main" style={{ marginTop: 10 }}>
                        <div className="banner-big">
                            {
                                !livestream && <div className="slider slider-banner">
                                    <Slider {...sliderBannerSetting}>
                                        {(!isEmpty(spotlights) ? spotlights : state.top_articles).map((item, index) => {
                                            return (
                                                <Link href={`/${getTypeArticle(item.post_type_id)}/${item.id}/${item.slug}`} key={index} passHref={true}>
                                                    <div key={index} className="items img-absolute" style={{ cursor: 'pointer' }}>
                                                        <Image layout={"fill"} src={item.storage_thumbnail_url_large} align="" alt={item.title} />
                                                        <div className="news-content">
                                                            <div className="news-col">
                                                                <div className="news-col-left">
                                                                    <p>
                                                                        <img src="/images/article-o.png" alt={item.title} />
                                                                        {item.title}
                                                                    </p>
                                                                </div>
                                                                <div className="news-col-right">
                                                                    <ul className="share-number">
                                                                        <li><img alt="" src="/images/icon-heart-white.svg" />{numberWithCommas(item.interaction_count?.like_count_number)}</li>
                                                                        <li><img alt="" src="/images/icon-comment-white.svg" />{numberWithCommas(item.interaction_count?.comment_count_number)}</li>
                                                                        <li><img alt="" src="/images/icon-tick.svg" />{numberWithCommas(item.interaction_count?.view_count_number)}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </Slider>
                                </div>
                            }
                            {
                                livestream && <div className="slider slider-banner iframe-fb">
                                    {createMarkup1()}
                                </div>
                            }
                        </div>
                        <div className="banner-small">
                            {
                                minuteNow != null && minuteNow % 2 == 0 && <SliderAd positionDesktop="17" pageDesktop="1" className="p-0 home-banner-long" />
                            }
                            {
                                minuteNow != null && minuteNow % 2 == 1 && <>
                                    <div className="small-items">
                                        <SliderAd positionDesktop="13" pageDesktop="1" className="p-0" />
                                    </div>
                                    <div className="small-items">
                                        <SliderAd positionDesktop="16" pageDesktop="1" className="p-0" />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <ul className="bb-sp list-tabs">
                        {
                            listTab.map((item, index) => {
                                if (isEmpty(item)) return <></>;
                                return (
                                    <li key={index}>
                                        <a href={item.link}>
                                            <span className="tabs-icon">
                                                <img
                                                    className={item.className || ''}
                                                    src={item.image}
                                                    align=""
                                                    alt=""
                                                />
                                                <span className="text-icon">{item.textIcon}</span>
                                            </span>
                                            <p className="tabs-text">{item.name}</p>
                                        </a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    <div className="banner-main sp-only" style={{ marginTop: 10 }}>
                        <div className="banner-big">
                            <div className="slider slider-banner">
                                {
                                    livestream && <div className="iframe-fb mb-4">
                                        {createMarkup1()}
                                    </div>
                                }
                                {
                                    !livestream && <Slider {...sliderBannerSetting}>
                                        {(!isEmpty(spotlights) ? spotlights : state.top_articles).map((item, index) => {
                                            return (
                                                <Link href={`/${getTypeArticle(item.post_type_id)}/${item.id}/${item.slug}`} key={index} passHref={true}>
                                                    <div>
                                                        <div key={index} className="items img-absolute" style={{ cursor: 'pointer' }}>
                                                            <Image layout={"fill"} src={item.storage_thumbnail_url_large} align="" alt={item.title} />
                                                            <div className="news-content">
                                                                <div className="news-col">
                                                                    <div className="news-col-left" />
                                                                    <div className="news-col-right">
                                                                        <ul className="share-number">
                                                                            <li><img alt="" src="/images/icon-heart-white.svg" />{numberWithCommas(item.interaction_count?.like_count_number)}</li>
                                                                            <li><img alt="" src="/images/icon-comment-white.svg" />{numberWithCommas(item.interaction_count?.comment_count_number)}</li>
                                                                            <li><img alt="" src="/images/icon-tick.svg" />{numberWithCommas(item.interaction_count?.view_count_number)}</li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="display-2-line" style={{ fontWeight: 500 }}>
                                                            <img src="/images/article-o.png" alt={item.title} />
                                                            {item.title}
                                                        </p>
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </Slider>
                                }
                            </div>
                        </div>
                    </div>
                    <ImageStore title="Đáng xem nhất" item={state.top_articles} isWatchMost={true} />
                    {!isEmpty(categories) && <div className="bb-sp popular-industry">
                        <div className="groupCategory-title">
                            <h2 className="groupCategory-tlt">Ngành hàng phổ biến</h2>
                            <a href="/shop/danh-muc" className="readMore">Xem tất cả</a>
                        </div>
                        <div className="serviceList">
                            <ul style={{
                                whiteSpace: 'nowrap',
                                overflowX: 'scroll',
                                display: 'block'
                            }}
                            >
                                {genCategories}
                            </ul>
                        </div>
                    </div>}
                    {(flashSales.length <= 0 && !isEmpty(categories)) && <div className="bb-sp product-hot">
                        <div className="groupCategory-title">
                            <h2 className="groupCategory-tlt">Sản phẩm hot</h2>
                        </div>
                        <ListSpHot />
                    </div>}
                    {flashSales.length > 0 && <div className="groupInfo bb-sp product-hot">
                        <div className="titleShop">
                            <h2 className="titleShop-title"><span>
                                <Image width={15} height={18} src="/images/icon-sale.svg" alt="" />
                            </span>Flash Sale
                            </h2>
                            <a href="/shop/flash-sale">Xem tất cả</a>
                        </div>
                        <div className="flashSale">
                            <div className="slider sliderFlashSale pc-only">
                                <Slider {...settingsFlashSale}>
                                    {
                                        flashSales.map((item, index) => {
                                            return <Product key={index} item={item} isFlashSale={true} />;
                                        })
                                    }
                                </Slider>
                            </div>
                            <div className="flashSale-sp">
                                {
                                    flashSales.slice(0, 6).map((item, index) => {
                                        return <Product key={index} item={item} isFlashSale={true} isHorizon={true} />;
                                    })
                                }
                            </div>
                        </div>
                    </div>}
                    {
                        renderByType(getDataByType(state.categories, 'Chuyện nhà'))
                    }
                    <div className="home-story">
                        <div className="groupCategory-title">
                            <h2 className="groupCategory-tlt">Gợi ý chuyên gia</h2>
                            <a href="/chuyen-gia" className="readMore">Xem tất cả</a>
                        </div>
                        <ul className="expert__list" style={{
                            whiteSpace: 'nowrap',
                            overflow: 'auto',
                            display: 'block'
                        }}>
                            {state.professionals.map((item, index) => {
                                return (<li key={index} className="mb-0" style={{ display: 'inline-block' }}>
                                    <NewProfessionalComponent item={item} />
                                </li>
                                );
                            })
                            }
                        </ul>
                    </div>
                    <SliderAd positionDesktop="2" pageDesktop="1" positionMobile="6" pageMobile="1" />
                    {!isEmpty(dealHots) && <div className="bb-sp deadlhot__wrap">
                        <div className="titleShop">
                            <h2 className="titleShop-title">Deal HOT!!!</h2>
                            <a href="/shop/deal-hot">Xem tất cả</a>
                        </div>
                        <div className="dealHot">
                            <Slider {...settingsDealHot}>
                                {genDealHots}
                            </Slider>
                        </div>
                    </div>}
                    {/* {home.events?.data && <HotEvent items={home.events.data} />} */}
                    {
                        renderByType(getDataByType(state.categories, 'Kho ảnh'))
                    }
                    {!isEmpty(getDataByType(state.categories, 'Chợ đồ cũ')) && <Markets title="Chợ đồ cũ" items={getDataByType(state.categories, 'Chợ đồ cũ').items.data} />}
                    <div className="bb-sp mb-5">
                        <div className="groupCategory-title mb-0">
                            <h2 className="groupCategory-tlt">Sản phẩm dành cho bạn</h2>
                            <a href="/shop/danh-muc" className="readMore">Xem tất cả</a>
                        </div>
                        <div className="productsLst">
                            <ul>
                                {products.map((item, index) => {
                                    return <li key={index + `${item.sku}`}>
                                        <Product item={item} />
                                    </li>;
                                })}
                            </ul>
                        </div>
                    </div>
                    {
                        state.categories.map(category => {
                            const exclude = ['Chuyện nhà', 'Chợ đồ cũ', 'Kho ảnh'];
                            if (exclude.indexOf(category.name) >= 0) return;
                            return renderByType(category);
                        })
                    }

                    <div className="home-story">
                        <div className="groupCategory-title">
                            <h2 className="groupCategory-tlt">Thành viên tích cực</h2>
                            {/* <a href="/chuyen-gia" className="readMore">Xem tất cả</a> */}
                        </div>
                        <ul className="expert__list" style={{
                            whiteSpace: 'nowrap',
                            overflow: 'auto',
                            display: 'block'
                        }}>
                            {state.members.map((item, index) => {
                                if (item.id == 2) return <></>;
                                return (<li key={index} className="mb-0" style={{ display: 'inline-block' }}>
                                    <NewProfessionalComponent item={item} isMember={true} />
                                    {/* <ProfessionalComponent item={item} /> */}
                                </li>
                                );
                            })
                            }
                        </ul>
                    </div>

                    <SliderAd positionDesktop="3" pageDesktop="1" positionMobile="7" pageMobile="1" />
                    <ModalUpdateInfo />
                </div>
            </section>
            {stateStore.consultantFilters.receive_advice && <>
                <div className="main main-form-dang-ky">
                    <div className="wrap">
                        <div className="row">
                            <div className="col-md-5 col-12 intro">
                                <p className="title">
                                    Đăng ký kết nối Thiết kế - thi công với Happynest
                                </p>
                                <p className="mt-3">
                                    Bạn đang cần tìm đơn vị thiết kế - thi công? Hãy nhập yêu cầu vào form đăng ký để Happynest hỗ trợ bạn kết nối với đơn vị phù hợp nhất nhé!
                                </p>
                                <ul className="mt-4 text-list">
                                    <li>
                                        Dễ dàng kết nối với đơn vị thiết kế, thi công phù hợp mong muốn và ngân sách
                                    </li>
                                    <li className="mt-3">
                                        Yên tâm nhận tư vấn, hỗ trợ từ đơn vị uy tín, chuyên nghiệp
                                    </li>
                                    <li className="mt-3">
                                        Tiết kiệm thời gian tìm kiếm, chọn lọc thông tin
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-7 col-12 mt-4 mt-md-0">
                                <FormDangKyTuVan hideHeader={true} displayFull={true} source={2} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-form-dang-ky2">
                    <img src="/images/form-tu-van/home-background2.png" alt="" style={{ width: '100%' }} />
                </div>
            </>}
            <div className="items sp-only" style={{ marginTop: 48 }}>
                <a>
                    <img className="sp-only" src="/images/logo-footer.png" alt="" style={{ width: 104, height: 72, margin: '0 auto 20px auto' }} />
                </a>

                <a className="mt-3">
                    <img className="sp-only" src="/images/logo-2-footer.png" alt="" style={{ width: '70%', height: 'auto', margin: '0 auto' }} />
                </a>
            </div>
        </>
    );
}

export const getServerSideProps = async () => {
    const categoriesParams = {
        filters: [[{
            field: 'parent_id',
            value: 1
        }]]
    };
    const dealHotParams = {
        page: 1,
        size: 3,
    };
    const productsParams = {
        size: 6,
    };
    const flashSalesParams = {
        size: 10,
    };
    const res = await Promise.all([
        http('community/home-v2', null, { token: '' }, 'v2'),
        Promise.resolve(''),
        Promise.resolve(''),
        http('/rest/V1/vendors/list-dealhot', dealHotParams),
        http('/rest/default/V1/product-suggestion-list', productsParams,),
        //http('community/spotlight', null, { token: '' }, 'v2'),
        http('/rest/V1/vne/homepage/flashsales/items', flashSalesParams),
    ]);
    const resSpotlight = await  http('community/spotlight', null, { token: '' }, 'v2');
    return {
        props: {
            home: res[0] || {},
            dealHots: res[3]?.items && res[3]?.items.length > 0 ? res[3].items : [],
            products: res[4]?.items || [],
            spotlights: resSpotlight?.data || [],
            flashSales: !isEmpty(res[5]?.items) ? res[5].items : [],
        }
    };
};

export const getFilters = (listOptions, categories) => {
    const chuDe = {
        "id": 0,
        "name": "Chủ đề",
        "slug": "chu-de-bai-dang",
        "order_number": 1,
        "select_one": true,
        "terms": (categories || []).map((item, index) => {
            let slug = item.slug;
            let name = item.name;
            if (item.slug === 'kho-anh') {
                slug = 'album';
            };
            if (item.slug.toLowerCase() === 'emagazine') {
                slug = 'happynest-story';
                name = 'Happynest Story';
            };
            return {
                "id": slug,
                "text": name,
                "order_number": index,
                "isChuDe": true,
            };
        })
    };
    return ([chuDe, ...listOptions] || []);
};

export const ModalUpdateInfo = () => {
    //Return an Antd Modal require user to update infomation Date of birth, Id and Address
    const [stateStore, actions] = useCart();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        visible: true,
        confirmLoading: false,
        cmnd_number: { ...inputModel, isRequired: true, placeholder: 'CMND/CCCD' },
        birthday: { ...inputModel, isRequired: true, placeholder: 'Ngày sinh' },
        address: { ...inputModel, isRequired: true, placeholder: 'Địa chỉ' },
    });

    const onSubmit = async () => {
        try {
            const res = await http('user/update-profile', {}, {
                body: {
                    ...stateStore.profileCommunity,
                    birthday: state.birthday.value,
                    address: state.address.value,
                    cmnd_number: state.cmnd_number.value,
                },
                method: 'POST',
                token: 'Bearer ' + getCookie(TOKEN_CONGDONG)
            }, 'v2');
            if (res.code === 200) {
                toast.info('Lưu thay đổi thành công!');
                handleCancel();
            } else {
                toast.error(res.errors);
            }
        } catch (e) {
            console.log(e);
            toast.error('Đã xảy ra lỗi!');
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setCookie(stateStore.profileCommunity?.id + 'updateInfo', true, 1 / 24);
    };

    const onChangeText = (slug) => {
        const func = e => {
            const newInfoData = { ...state };
            newInfoData[slug] = {
                ...newInfoData[slug],
                value: e.target.value,
                error: ''
            };
            setState(newInfoData);
        };
        return func;
    };

    useEffect(() => {
        if (isEmpty(stateStore.profileCommunity)) return;
        const userTemp = stateStore.profileCommunity;
        setState({
            ...state,
            cmnd_number: {
                ...state.cmnd_number,
                value: userTemp.cmnd_number,
            },
            birthday: {
                ...state.birthday,
                value: userTemp.birthday,
            },
            address: {
                ...state.address,
                value: userTemp.address,
            },
        });

        if (
            (isEmpty(userTemp.birthday) || isEmpty(userTemp.cmnd_number) || isEmpty(userTemp.address))
            && !(getCookie(stateStore.profileCommunity.id + 'updateInfo') === true)) setOpen(true);
    }, [stateStore.profileCommunity]);

    return <Modal
        title="Thông báo"
        open={open}
        onCancel={handleCancel}
        footer={false}
    >
        <p className="font-size-16">Để sử dụng đầy đủ các tính năng và nhận các chương trình ưu đãi dành riêng cho thành viên cộng đồng Happynest, bạn vui lòng cập nhật đầy đủ các thông tin tài khoản của mình nhé!</p>
        <div className="register-lamnha-form mt-2">
            <Row gutter={8}>
                <Col span={24}>
                    <label>Ngày sinh</label>
                    <MyInput className="mb-2 mt-0" data={state.birthday} onChange={onChangeText('birthday')} />
                </Col>
                <Col span={24}>
                    <label>CMND/CCCD</label>
                    <MyInput className="mb-2 mt-0" data={state.cmnd_number} onChange={onChangeText('cmnd_number')} />
                </Col>
                <Col span={24}>
                    <label>Địa chỉ</label>
                    <MyInput className="mb-2 mt-0" data={state.address} onChange={onChangeText('address')} />
                </Col>
                <Col span={24}>
                    <button style={{ marginTop: 12 }} className="btn btn--blue w-100" onClick={() => onSubmit()}>
                        Cập nhật
                    </button>
                </Col>
            </Row>
        </div>
    </Modal>;
};