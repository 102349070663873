/* eslint-disable @next/next/no-img-element */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Link from 'next/link';

export default function Index({ banner, setBanner, imgLink, link = '/shop' }) {
    const [img, setImg] = useState(imgLink || "/images/banner-profile-sp.png");
    return <Modal show={banner} onHide={() => setBanner(false)} role="document" className={"modalCenter"}>
        {/* <Modal.Header closeButton>
            <h5 className="modal-title">Banner</h5>
        </Modal.Header> */}
        <Modal.Body >
            <Link href={link}><a>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img className="svg" src={img} alt="" />
                </div>
            </a></Link>
        </Modal.Body>
    </Modal>;
}